
<template>
  <div class="expense-wrapper">
    <div>
      <!-- <EasyDataTable :headers="headers" :items="expenses" :sort-by="'date'" :sort-type="'desc'" :theme-color="'#f48225'"
        :filter-options="filterOptions">
        <template #header-account.name="header">
          <div class="filter-column">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAx9JREFUaEPtmUuojVEUx383E0SRSFFIGSAlDBiIKAwYeIXyJvIoFInyKhQDBgh5K28GDLwjAxNJCkWEAQbEAGWi9Ne6+tzznfOt/e1zrnPz7dG95/zXf+3/XmuvvfY+DbTw0dDC589/IaAvsAqYAHSuYcQ+A9eBM8Blr5+sCGjy54B+XsIq4D4BY4GHHq4sAYeA+UZ0DHjrIc2J6QHMMdvDwAIPT5aAJ7b6m4FNHsJIjHxsBJ4C/T1cWQI+AF2B5hag/dCpGgK2A2uB+8AY4JuHNCemnW3iYcBpYIaHJysCI4A7RnQAWOwhzYnZDywy26nAeQ9PlgBxbLAU0t/LgL0e4kDMUmCP2WgPbPHaewSIS6sxGfhhqXTP68CBG26p0xq4AExx2PyBeAX0NCd9gAcm4kuIozLYjsY7BHhhvG9CeL0CxDk+cUIeSZwPIf6aYlXv59mHOumvhJKFCBD3OmCrOVkJ7A51mMCvAHbZ/+uBbXm4QgXIh0rcNOCnhfx2DsejLHVaWe8zPQfHb5M8ArqZc/VHj0zEx4AJqCFU0zbQTlydL+8C7P+C5hEgAjVbV43pBDA7YALHgVmGHwdcC7AtgeYVIKLVwA5jVB3f55jIXEAFQGMNsNNhUxESI0DEWv2ZwCtgKJCVSjeB0cDJRBSiNMQKmAhctBlMAi5VmI3OkOf2vSKh9lxjENA+Q4XuBl/TMLECxPkdaAssT7QDab5UaU7ZFyoAzwAdkK8dIZCAwbUSoGZPTV9Wy93Y62sejQvXG3jpEFD2flCNCMQI0Ny1hySk0rhhLX0Jph4EOAJQHlIIsAtP3j0QtfrJzRRDFLsHYnzn6oWaOowRoDKqdyfdByqNu8DIeiyjA4DHjhDoktOrHgW0AZY4TuL3wMF6FOBY/MqQoowWZTQyif51CnUAbllLXUlK2afGagg4as/iCwE9x5cbad2ot4zqotSlVlVIfX53e/DSgRMiQFhdK1N7/QTRWUBvpyWjGhHwZnFaBLy2ZXGFgIAlLCJQ7IGAdEmDtvgUkij96qkfMar2i2dzVqHIAKabFwJqsqwBpL8AiSSfMe3JHmMAAAAASUVORK5CYII="
              class="filter-icon" @click.stop="showAccountFilter = !showAccountFilter" />

            {{ header.text }}
            <div class="filter-menu filter-sport-menu" v-if="showAccountFilter">
              <select v-model="account" v-if="this.accounts.length > 0">
                <option value="all">All</option>
                <option v-for="item in accounts" :key="item.id" :value="item.name">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </template>
        <template #header-category.name="header">
          <div class="filter-column">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAx9JREFUaEPtmUuojVEUx383E0SRSFFIGSAlDBiIKAwYeIXyJvIoFInyKhQDBgh5K28GDLwjAxNJCkWEAQbEAGWi9Ne6+tzznfOt/e1zrnPz7dG95/zXf+3/XmuvvfY+DbTw0dDC589/IaAvsAqYAHSuYcQ+A9eBM8Blr5+sCGjy54B+XsIq4D4BY4GHHq4sAYeA+UZ0DHjrIc2J6QHMMdvDwAIPT5aAJ7b6m4FNHsJIjHxsBJ4C/T1cWQI+AF2B5hag/dCpGgK2A2uB+8AY4JuHNCemnW3iYcBpYIaHJysCI4A7RnQAWOwhzYnZDywy26nAeQ9PlgBxbLAU0t/LgL0e4kDMUmCP2WgPbPHaewSIS6sxGfhhqXTP68CBG26p0xq4AExx2PyBeAX0NCd9gAcm4kuIozLYjsY7BHhhvG9CeL0CxDk+cUIeSZwPIf6aYlXv59mHOumvhJKFCBD3OmCrOVkJ7A51mMCvAHbZ/+uBbXm4QgXIh0rcNOCnhfx2DsejLHVaWe8zPQfHb5M8ArqZc/VHj0zEx4AJqCFU0zbQTlydL+8C7P+C5hEgAjVbV43pBDA7YALHgVmGHwdcC7AtgeYVIKLVwA5jVB3f55jIXEAFQGMNsNNhUxESI0DEWv2ZwCtgKJCVSjeB0cDJRBSiNMQKmAhctBlMAi5VmI3OkOf2vSKh9lxjENA+Q4XuBl/TMLECxPkdaAssT7QDab5UaU7ZFyoAzwAdkK8dIZCAwbUSoGZPTV9Wy93Y62sejQvXG3jpEFD2flCNCMQI0Ny1hySk0rhhLX0Jph4EOAJQHlIIsAtP3j0QtfrJzRRDFLsHYnzn6oWaOowRoDKqdyfdByqNu8DIeiyjA4DHjhDoktOrHgW0AZY4TuL3wMF6FOBY/MqQoowWZTQyif51CnUAbllLXUlK2afGagg4as/iCwE9x5cbad2ot4zqotSlVlVIfX53e/DSgRMiQFhdK1N7/QTRWUBvpyWjGhHwZnFaBLy2ZXGFgIAlLCJQ7IGAdEmDtvgUkij96qkfMar2i2dzVqHIAKabFwJqsqwBpL8AiSSfMe3JHmMAAAAASUVORK5CYII="
              class="filter-icon" @click.stop="showCategoryFilter = !showCategoryFilter" />

            {{ header.text }}
            <div class="filter-menu filter-sport-menu" v-if="showCategoryFilter">
              <select v-model="category" v-if="this.categories.length > 0">
                <option value="all">All</option>
                <option v-for="item in categories" :key="item.id" :value="item.name">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </template>
        <template #item-date="item"> {{ item.date.slice(0, 10) }} </template>
        <template #item-amount="item"> {{ item.amount }} DKK </template>
        <template #loading>
          <img src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
            style="width: 100px; height: 80px" />
        </template>
      </EasyDataTable> -->
      <DataTable v-model:filters="filters" :value="expenses" tableStyle="min-width: 50rem" paginator :rows="20"
        sortField="date" :sortOrder="-1" filterDisplay="menu" :globalFilterFields="['description']">
        <template #header>
          <div class="header-controls">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
            </span>
          </div>
        </template>
        <Column header="Date" filterField="date" dataType="date" style="min-width: 10rem">
          <template #body="{ data }">
            {{ formatDate(data.date) }}
          </template>
        </Column>
        <Column field="description" header="Description" :showFilterMatchModes="false">
          <template #body="{ data }">
            {{ data.description }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
              placeholder="Search by description" />
          </template>
        </Column>
        <Column field="amount" header="Amount" :showFilterMatchModes="false" style="min-width: 12rem" sortable>
          <template #body="{ data }">
            {{ data.amount }} DKK
          </template>
          <template #filter="{ filterModel }">
            <Slider v-model="filterModel.value" range class="m-3" :max="50000" :step="100"></Slider>
            <div class="flex align-items-center justify-content-between px-2">
              <span>{{ filterModel.value ? filterModel.value[0] : 0 }}</span>
              <span>{{ filterModel.value ? filterModel.value[1] : 50000 }}</span>
            </div>
          </template>
        </Column>
        <Column header="Category" filterField="category" :showFilterMatchModes="false"
          :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
          <template #body="{ data }">
            <div class="flex align-items-center gap-2">
              <span>{{ data.category.name }}</span>
            </div>
          </template>
          <template #filter="{ filterModel }">
            <MultiSelect v-model="filterModel.value" :options="categories" optionLabel="name" placeholder="Any"
              class="p-column-filter">
              <template #option="slotProps">
                <div class="flex align-items-center gap-2">
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column header="Account" filterField="account" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }"
          style="min-width: 14rem">
          <template #body="{ data }">
            <div class="flex align-items-center gap-2">
              <span>{{ data.account.name }}</span>
            </div>
          </template>
          <template #filter="{ filterModel }">
            <MultiSelect v-model="filterModel.value" :options="accounts" optionLabel="name" placeholder="Any"
              class="p-column-filter">
              <template #option="slotProps">
                <div class="flex align-items-center gap-2">
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="internalMove" header="Internal move" dataType="boolean" bodyClass="text-center"
          style="min-width: 8rem">
          <template #body="{ data }">
            <i class="pi"
              :class="{ 'pi-check-circle text-green-500 ': data.internalMove, 'pi-times-circle text-red-500': !data.internalMove }"></i>
          </template>
          <template #filter="{ filterModel }">
            <label for="internal-filter" class="font-bold"> Verified </label>
            <TriStateCheckbox v-model="filterModel.value" inputId="internal-filter" />
          </template>
        </Column>
        <Column field="income" header="Income" dataType="boolean" bodyClass="text-center" style="min-width: 8rem">
          <template #body="{ data }">
            <i class="pi"
              :class="{ 'pi-check-circle text-green-500 ': data.income, 'pi-times-circle text-red-500': !data.income }"></i>
          </template>
          <template #filter="{ filterModel }">
            <label for="income-filter" class="font-bold"> income </label>
            <TriStateCheckbox v-model="filterModel.value" inputId="income-filter" />
          </template>
        </Column>

      </DataTable>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { api } from "@/api/api";
import { FilterMatchMode, FilterOperator } from 'primevue/api';
var _ = require("lodash");

export default {
  mixins: [api],

  // eslint-disable-next-line
  name: "Expense",
  data() {
    return {
      expenses: [],
      categories: [],
      category: "all",
      accounts: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        internalMove: { value: null, matchMode: FilterMatchMode.EQUALS },
        income: { value: null, matchMode: FilterMatchMode.EQUALS },
        account: { value: null, matchMode: FilterMatchMode.IN },
        category: { value: null, matchMode: FilterMatchMode.IN },
        amount: { value: [0, 50000], matchMode: FilterMatchMode.BETWEEN },
      },
    };
  },
  created() {
    this.getExpenses().then((response) => {
      this.expenses = _.orderBy(response, "date", "desc");
    });
    this.getAccounts().then((response) => {
      this.accounts = response;
    });
    this.getCategories().then((response) => {
      this.categories = _.orderBy(response, "name", "asc");
    });
  },
  methods: {
    formatDate(value) {

      let date = new Date(value)

      return date.toLocaleDateString('da-DK', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
  },
  computed: {
    filterOptions() {
      const filterOptionsArray = [];
      if (this.account !== "all") {
        filterOptionsArray.push({
          field: "account.name",
          comparison: "=",
          criteria: this.account,
        });
      }
      if (this.category !== "all") {
        filterOptionsArray.push({
          field: "category.name",
          comparison: "=",
          criteria: this.category,
        });
      }
      return filterOptionsArray;
    },
  },
};
</script>

<style>
.expense-wrapper {
  margin: 10px;
}

.filter-icon {
  cursor: pointer;
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  margin-right: 4px;
}
</style>