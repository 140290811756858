<template>
  <div class="container">
    <div class="row mt-5">
      <div class="card bg-secondary shadow border-0">
        <p>Expenses this year</p>

        <div class="card-header">
          
          <input-checkbox :id="'hideVacation'" :initial-state="hideVacation" :text="'Hide vacation'"
            @change-value="(n) => (hideVacation = n)"></input-checkbox>
          <div>
            <select v-model="selectedYear" required class="input-group input-group-alternative mb-3 form-control">
              <option v-for="year in years" id="year" :value="year.value" :key="year.value">
                {{ year.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="card-body">

          <apex-bar :chart-data="expenseByMonth.data" :chart-labels="expenseByMonth.labels" :emoji="'Expenses'"
            :colors="chartColors" :color="this.chartColor" :border="'#FF0000'" :chart-data-type="'bar'" :rotate="true"
            :xAxisRotate="-45" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/api/api";
import moment from "moment";
import ApexBar from "@/components/Charts/ApexBar";
import InputCheckbox from "@/components/Checkbox";

var _ = require("lodash");

export default {
  name: "LastMonth",
  mixins: [api],

  components: {
    ApexBar,
    InputCheckbox
  },
  data() {
    return {
      selectedYear: new Date().getFullYear(),
      // selectedYear: new Date().getFullYear(),
      accounts: [],
      expenses: [],
      years: [
        { name: "2024", value: "2024" },
        { name: "2023", value: "2023" }
      ],
      chartColors: ["#F55555", "#6078ea", "#6094ea"],
      hideVacation: false,
      color: {
        hue: 50,
        saturation: 100,
        luminosity: 50,
        alpha: 1,
      },
    };
  },
  created() {
    this.getExpenses().then((response) => {
      this.expenses = response
        .map((entry) => {
          entry.raw_time = new moment.utc(entry.date);
          entry.year = entry.raw_time.format("YYYY");
          entry.month = entry.raw_time.format("MM");
          entry.week = entry.raw_time.format("WW");
          entry.day = entry.raw_time.isoWeekday();
          entry.time = entry.date.replace("Z", "");
          return entry;
        })
        .filter((expense) => !expense.income && !expense.internalMove);
    });
  },
  methods: {
    HSLToRGB(h, s, l) {
      // Must be fractions of 1
      s /= 100;
      l /= 100;

      let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = l - c / 2,
        r = 0,
        g = 0,
        b = 0;
      if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
      } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
      } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
      } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
      } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
      } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
      }
      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);

      return "rgb(" + r + "," + g + "," + b + ")";
    },
  },
  computed: {
    chartColor() {
      var hue = this.color.hue;
      var rgb = this.HSLToRGB(Math.floor(hue), 100, 50);
      return rgb;
    },
    internalMove() {
      let data = this.expenses;
      let exp = data.filter(item => item.movedTo != null);
      return exp;
    },
    expenseByMonth() {
      let hideVacay = this.hideVacation;
      let data = this.expenses;

      if (hideVacay) {
        data = data.filter(item => item.category._id != '6399c64368857a68f9c1fb43');
      }

      data = data.filter (item => item.year == this.selectedYear.toString())

      var count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let months = _(data)
        .groupBy("month")
        .map((objs, key) => {
          return {
            month: key,
            count: objs.length,
          };
        })
        .value();


      months = _.orderBy(months, "month", "asc");
      for (var key in months) {
        var index = Number(
          moment()
            .month(parseInt(months[key].month) - 1)
            .format("MM") - 1
        );

        let expenseRecords = data.filter(
          (obj) => moment(obj.date).format("MM") == index + 1 // Add one to month
        );



        // if (hideAllTransferred) {
        //   expenseRecords = expenseRecords.filter(item => item.internalMove == true);
        // }

        var prices = expenseRecords.map((obj) => obj.amount);
        // count[index] = months[key].count;
        count[index] = _.sum(prices);
      }

      return {
        data: count,
        labels: labels,
      };
    },
  },
};
</script>


<style scoped>
@import "../../assets/styles/bootstrap-overrides.css";

.container {
  padding: 10px;
  height: 100%;
}

.card-header{
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: unset;
}

</style>