<template>
  <nav class="navbar">
    <div class="routes-wrapper" v-if="currentRoute.name != undefined">
      <span
        v-for="r in routes"
        :key="r.route"
        v-bind:class="{
          selected: currentRoute.name.toLowerCase() == r.title.toLowerCase(),
        }"
        class="page-btn text-color"
        @click="goToPage(r.route)"
        >{{ r.title }}</span
      >
    </div>
  </nav>
</template>
  
  <script>
export default {
  // eslint-disable-next-line
  name: "Header",
  data() {
    return {
      currentRoute: "",
      routes: [
        { route: "/home", title: "Dashboard" },
        { route: "expenses", title: "Expenses" },
        { route: "stats", title: "Stats" },
        { route: "stats2", title: "Stats2" },
      ],
    };
  },
  components: {},
  computed: {
    width() {
      let maxWidth = window.outerWidth + 15;
      if (maxWidth > 750) {
        maxWidth = 750;
      }

      return maxWidth.toString();
    },
  },
  created() {
    console.log(this.$route);
    this.currentRoute = this.$route;
    if (this.currentRoute.name == undefined) {
      this.currentRoute = "";
    }
  },
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
  },
  watch: {
    $route(to, from) {
      console.log(from);
      this.currentRoute = to;
    },
  },
};
</script>
  
  <style scoped>
  
  .navbar {
      text-align: center;
      background-color: #313144;
      display: flex;
      justify-content: center;
      align-items: center;;
  }
  
  .navbar {
      border: 0;
      font-size: 14px;
      transition: all .4s;
      -webkit-transition: all .4s;
      padding: 0;
      -webkit-box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
      box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  }
  
  .navbar, .navbar>.container, .navbar>.container-fluid {
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: center;
      -webkit-box-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      -webkit-box-pack: justify;
      justify-content: space-between;
  }
  
  .navbar {
      position: relative;
      padding: 0.5rem 1rem;
  }
  
  .routes-wrapper{
      width:100%;
  }
  
  
  
  .text-color {
    color: #FFF !important;
  }
  
  .text-color:hover {
    font-weight: 700;
    background-image: linear-gradient(
      90deg,
      #fe8c00,
      #FC6601,
      #f83600
    );
    color: transparent !important;
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  .navbar {
    background-color: #CEDBED) !important;
    justify-content: space-between;
  }
  
  .logout {
    position: fixed;
    bottom: 0;
  }
  
  .avatar-pic {
    margin-right: 10px;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2),
      0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
  }
  
  .avatar-pic:hover {
    transform: scale(1.3);
  }
  
  .logo-header {
    height: 36px;
  }
  
  .selected {
    /* border-bottom: 3px solid orange; */
    text-align: center;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(
      90deg,
      #fe8c00,
      #FC6601,
      #f83600
    );
    border-image-slice: 1;
    width: 100%;
  }
  
  .page-btn {
    position: relative;
    height: 28px;
    font-size: 16px;
    font-weight: 500;
    color: hsla(0, 0%, 100%, 0.8);
    line-height: 28px;
    margin: 0 16px;
    font-weight: 700;
  }
  
  .icons {
    display: flex;
  }
  </style>