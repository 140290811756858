import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../views/LandingPage.vue'
import Expenses from '../views/Expenses.vue'
import Login from '../views/Login.vue'
import Stats from '../views/Stats.vue'
import Stats2 from '../views/Stats2.vue'
import NotFound from '../views/404.vue'

// import axios from "axios";


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/home',
            name: 'Dashboard',
            component: LandingPage,
        },
        {
            path: '/stats',
            name: 'Stats',
            component: Stats,
        },
        {
            path: '/stats2',
            name: 'Stats2',
            component: Stats2,
        },
        {
            path: '/expenses',
            name: 'Expenses',
            component: Expenses,
        },
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },

    ],
});

router.beforeEach((to, from, next) => {
    // console.log(`Going from ${from.path} --> ${to.path}`)
    if (to.meta.requiresAuth) {
        const loginInfo = localStorage.getItem('password');
        this.$http
            .post(`validate`, { password: loginInfo })
            .then((response) => {
                if (response.status == 200) {
                    next()
                } else {
                    next('/protected')
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    else {
        next()
    }
})


export default router;