<template>
  <div id="app">
    <app-header ></app-header>
    <div class="content-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import NewHeader from "@/components/Header";
export default {
  components: {
    appHeader: NewHeader,
  },
};
</script>


<style>
@import "./assets/styles/theme.css";

html {
  min-height: 100%;
}

body {
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;
  color: #525f7f !important;
  background: linear-gradient(
    90deg,
    rgba(254, 87, 46, 1) 0%,
    rgba(255, 146, 63, 1) 100%
  );
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
}

.content-container {
  max-width: 2500px;
  margin: auto;
  /* padding: 10px; */
}
</style>
