<template>
  <div class="dashboard-row">
    <new-expense></new-expense>
    <last-month></last-month>
  </div>

  <!-- <expense></expense> -->
</template>

<script>
/* eslint-disable */
// import Expense from "@/components/Expense.vue";
import NewExpense from "@/components/Forms/NewExpense.vue";
import LastMonth from "@/views/sub/LastMonth.vue";

export default {
  components: {
    LastMonth,
    NewExpense,
  },
};
</script>

<style scoped>
.dashboard-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

@media only screen and (max-width: 768px) {
  .dashboard-row {
    display: grid;
    grid-row-gap: 1em;
    grid-column-gap: 1em;
    grid-template-columns: repeat(1, minmax(1px, 1fr));
  }
}
</style>