<template>
  <div>
    <div id="chart">
      <apexchart
        ref="barchart"
        type="bar"
        :height="390"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    /* eslint-disable no-eval */
    chartData: {
      type: Array,
      required: true,
    },
    chartLabels: {
      type: Array,
      required: true,
    },
    colors: {
      type: String,
      required: true,
    },
    border: {
      type: String,
      required: false,
    },
    tooltipColor: {
      type: String,
      required: false,
    },
    labelColor: {
      type: String,
      required: false,
    },
    seriesName: {
      type: String,
    },
    chartDataType: {
      type: String,
      required: false,
    },
    xAxisLabel: {
      type: String,
      required: false,
    },
    xAxisTicks: {
      type: Number,
      required: false,
    },
    xAxisRotate: {
      type: Number,
      required: false,
    },
    rotate: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    yAxisTitle: {
      type: String,
      required: false,
    },
    startAt: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    getLabelFormat(val) {
      // return val;
      if (this.chartDataType == "pace") {
        var seconds = Math.floor(val);
        return new Date(seconds * 1000).toISOString().substr(14, 5);
      } else {
        if (isNaN(Math.floor(val))) {
          return val;
        } else {
          return Math.floor(val);
        }
      }
    },
  },
  data: function () {
    return {
      series: [
        {
          name: this.emoji,
          data: this.chartData,
        },
      ],
      chartOptions: {
        colors: this.colors,
        chart: {
          type: "bar",
          height: "auto",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: this.horizontal,
            columnWidth: "80%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        grid: {
          show: false,
        },
        xaxis: {
          type: "category",
          tickPlacement: "on",
          tickAmount: this.xAxisTicks || undefined,
          categories: this.chartLabels,
          labels: {
            show: true,
            rotateAlways: this.rotate,
            rotate: this.xAxisRotate || -45,
            hideOverlappingLabels: true,
            style: {
              colors: Array.from({ length: this.chartLabels.length }).fill(
                this.labelColor
              ),
            },
          },
        },

        yaxis: {
          min: this.startAt,
          title: {
            text: this.yAxisTitle,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: this.labelColor,
            },
          },
          labels: {
            formatter: (val) => {
              return this.getLabelFormat(val);
            },
            style: {
              colors: Array.from({ length: this.chartLabels.length }).fill(
                this.labelColor
              ),
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.25,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.8,
            stops: [0, 100],
            // gradientToColors: this.colors,
            gradientToColors: ["#F55555", "#6078ea", "#6094ea"], // TODO look into these gradient thingies
          },
        },
        tooltip: {
          theme: "dark",
          enabled: true,
          enabledOnSeries: undefined,
          shared: false,
          onDatasetHover: {
            highlightDataSeries: false,
          },
          x: {
            show: true,
            format: "dd MMM",
            formatter: undefined,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
          marker: {
            show: true,
          },
        },
      },
    };
  },
  watch: {
    labelColor: function () {
      this.$refs.barchart.updateOptions({
        xaxis: {
          type: "category",
          tickPlacement: "on",
          tickAmount: this.xAxisTicks || undefined,
          categories: this.chartLabels,
          labels: {
            show: true,
            rotate: this.xAxisRotate || -45,
            rotateAlways: this.rotate,
            hideOverlappingLabels: true,

            style: {
              colors: Array.from({ length: this.chartLabels.length }).fill(
                this.labelColor
              ),
            },
          },
        },
        yaxis: {
          title: {
            text: this.yAxisTitle,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: this.labelColor,
            },
          },
          labels: {
            formatter: (val) => {
              return this.getLabelFormat(val);
            },
            style: {
              colors: Array.from({ length: this.chartLabels.length }).fill(
                this.labelColor
              ),
            },
          },
        },
      });
    },
    chartData: function () {
      this.$refs.barchart.updateSeries([{ data: this.chartData }], false, true);
      this.$refs.barchart.updateOptions({
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: this.horizontal,
            columnWidth: "80%",
            endingShape: "rounded",
          },
        },
        xaxis: {
          type: "category",
          tickPlacement: "on",
          tickAmount: this.xAxisTicks || undefined,
          categories: this.chartLabels,
          labels: {
            show: true,
            rotate: this.xAxisRotate || -45,
            rotateAlways: this.rotate,
            hideOverlappingLabels: true,

            style: {
              colors: Array.from({ length: this.chartLabels.length }).fill(
                this.labelColor
              ),
            },
          },
        },
        yaxis: {
          title: {
            text: this.yAxisTitle,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#FFF",
            },
          },
          labels: {
            formatter: (val) => {
              return this.getLabelFormat(val);
            },
            style: {
              colors: Array.from({ length: this.chartLabels.length }).fill(
                this.labelColor
              ),
            },
          },
        },
      });
    },
    yAxisTitle: function () {
      this.$refs.barchart.updateOptions({
        yaxis: {
          title: {
            text: this.yAxisTitle,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#FFF",
            },
          },
          labels: {
            formatter: (val) => {
              return this.getLabelFormat(val);
            },
            style: {
              colors: Array.from({ length: this.chartLabels.length }).fill(
                "#FFF"
              ),
            },
          },
        },
      });
    },
  },
};
</script>
<style>
.apexcharts-menu {
  background-color: #27293d !important;
  border: 0px;
}
.apexcharts-menu-item:hover {
  background-color: #5a5c70 !important;
  border: 0px;
}
</style>