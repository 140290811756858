<template>
  <div class="container">
    <div class="row mt-5">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <p>Add Expense</p>
          <input-checkbox :id="'clearValues'" :initial-state="clearValues" :text="'Clear values'"
            @change-value="(n) => (clearValues = n)"></input-checkbox>

          <form role="form" @submit.prevent="onSubmit">
            <div class="form-group">
              <div class="form-col">
                <label for="antal_kort">Date</label>
                <div class="input-group input-group-alternative mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-single-02"></i></span>
                  </div>
                  <input class="form-control" type="date" v-model="expense.date" id="date" required />
                </div>
              </div>
              <div class="form-col">
                <label for="description">Description</label>
                <div class="input-group input-group-alternative mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-single-02"></i></span>
                  </div>
                  <input class="form-control" placeholder="" v-model="expense.description" id="description" required />
                </div>
              </div>
              <div class="form-col">
                <label for="amount">Amount</label>
                <div class="input-group input-group-alternative mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-single-02"></i></span>
                  </div>
                  <input class="form-control" type="number" placeholder="0" v-model="expense.amount" id="amount"
                    required />
                </div>
              </div>
              <div class="form-col">
                <input-checkbox :id="'isIncome'" :initial-state="expense.income" :text="'Is Income'"
                  @change-value="(n) => (expense.income = n)"></input-checkbox>
              </div>
              <div class="form-col">
                <label for="category">Category</label>
                <select v-model="expense.category" required class="input-group input-group-alternative mb-3 form-control">
                  <option v-for="cat in categories" id="category" :value="cat._id" :key="cat._id">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
              <div class="form-col">
                <label for="account">Account</label>
                <select v-model="expense.account" required class="input-group input-group-alternative mb-3 form-control">
                  <option v-for="account in accounts" id="account" :value="account._id" :key="account._id">
                    {{ account.name }}
                  </option>
                </select>
              </div>
              <!--             
              <div class="form-col">
                <label for="account">Account</label>
                <select
                  v-model="expense.account" 
                  class="input-group input-group-qternative mb-3 form-control"
                  required
                >
                  <option
                    v-for="acc in accounts"
                    id="account"
                    :value="acc._id"
                    :key="acc._id"
                  >
                    {{ acc.name }}
                  </option>
                </select>
              </div> -->
              <div class="form-col">
                <input-checkbox :id="'internalMove'" :initial-state="expense.internalMove" :text="'Internal move'"
                  @change-value="(n) => (expense.internalMove = n)"></input-checkbox>
              </div>

              <div class="form-col" v-if="expense.internalMove">
                <label for="category">Moved to Account</label>
                <select v-model="expense.movedTo" class="input-group input-group-alternative mb-3 form-control">
                  <option v-for="acc in accounts" id="account" :value="acc._id" :key="acc._id">
                    {{ acc.name }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <button class="btn btn-primary btn-block w-75 my-4">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputCheckbox from "@/components/Checkbox";

import { api } from "@/api/api";
var _ = require("lodash");

export default {
  mixins: [api],

  components: {
    InputCheckbox,
  },
  data() {
    return {
      clearValues: false,
      accounts: [],
      categories: [],
      expense: {
        date: this.getDate(),
        description: "",
        account: "",
        category: "",
        amount: 0,
        income: false,
        internalMove: false,
        movedTo: null,
      },
    };
  },
  created() {
    this.getCategories().then((response) => {
      this.categories = _.sortBy(response, "name");
    });
    this.getAccounts().then((response) => {
      this.accounts = _.sortBy(response, "name");
    });
  },
  methods: {
    getDate() {
      let today = new Date();

      let day = today.getDate();
      let month = today.getMonth();
      let year = today.getFullYear();
      if (Number(month) < 10) {
        return `${year}-0${month + 1}-${day}`;
      } else {
        return `${year}-${month + 1}-${day}`;
      }
    },
    onSubmit() {
      this.addNewExpense(this.expense);
      if (this.clearValues) {
        this.expense = {
          date: this.getDate(),
          description: "",
          account: "",
          category: "",
          amount: 0,
          income: false,
          internalMove: false,
          movedTo: null,
        };
      }else{
        this.expense.internalMove = false;
        this.expense.movedTo = null;
        this.expense.income = false;
      }

    },
  },
};
</script>
  
<style scoped>
@import "../../assets/styles/bootstrap-overrides.css";

h1 {
  color: var(--font-color);
}

.container {
  padding: 10px;
}

.form-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.form-col {
  /* The important bit. This percentage decides your columns. 
        The percent can be px. It just represents your minimum starting width.
    */

  flex: 1 0 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.card- {
  max-height: 500px;
}
</style>