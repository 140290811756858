// var _ = require("lodash");
import { useToast } from "vue-toastification";

export const api = {
    data() {
        return {
            error: false,
            errorMsg: "",
            loading: false,
        };
    },
    methods: {
        // async getExpenses() {
        //     await this.$http.get(`/expenses`).then((response) => {
        //         if (response.status == 200) {
        //             this.expenses = response.data;
        //         }
        //     });
        // },
        async getExpenses() {
            let response = await this.$http.get(`/expenses`);
            if (response.status == 200) {
                return response.data;
            }
        },
        async getAccounts() {
            let response = await this.$http.get(`/expenses/accounts`);
            if (response.status == 200) {
                return response.data;
            }
        },
        async getCategories() {
            let response = await this.$http.get(`/expenses/categories`);
            if (response.status == 200) {
                return response.data;
            }
        },
        async addNewExpense(expense) {
            // let apiKey = localStorage.getItem("apiKey");
            const toast = useToast();

            let apiKey = "XibJ2yAPI";
            const config = {
                headers: { 'api-key': apiKey },
            };

            await this.$http.post(`/expenses`, expense, config).then((response) => {
                if (response.status == 201) {
                    toast.success("Added expense")
                }
            });
        },

    },
}