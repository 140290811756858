<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-lg-6 col-md-8">
        <div class="card bg-secondary shadow border-0">
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-muted text-center mt-2 mb-4">
              <h3>Login</h3>
            </div>
            <form role="form" @submit.prevent="loginUser(login)">
              <div class="form-group">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-lock-circle-open"></i
                    ></span>
                  </div>
                  <input
                    class="form-control"
                    placeholder="Password"
                    id="password"
                    type="password"
                    v-model="login.password"
                    autocomplete="on"
                    required
                  />
                </div>
              </div>
              <div>
                <!-- Sign in button -->
                <center>
                  <button
                    class="btn btn-primary btn-block w-75 my-4"
                    type="submit"
                  >
                    Login
                  </button>
                </center>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      login: {
        password: "",
      },
    };
  },
  created() {
    let login = {
      password: localStorage.getItem("password"),
    };
    this.loginUser(login);
  },
  methods: {
    async loginUser(loginInfo) {
      try {
        await this.$http
          .post(`/validate`, loginInfo)
          .then((response) => {
            localStorage.setItem("password", loginInfo.password);
            if (response.status == 200) {
              this.$router.push("/home");
            } else {
              localStorage.setItem("password", "");
              alert("Wrong password");
            }
          })
          .catch(function (error) {
            localStorage.setItem("password", "");
            console.log(error);
          });
      } catch (err) {
        console.log(err.response);
      }
    },
  },
};
</script>
  <style scoped>
@import "../assets/styles/bootstrap-overrides.css";
.bg-secondary {
  background-color: #f5f6ff !important;
}
</style>