import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import axios from "axios";
import VueApexCharts from 'vue3-apexcharts'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import Toast from "vue-toastification";
import PrimeVue from 'primevue/config';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';                   // optional
import InputText from 'primevue/inputtext';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import MultiSelect from 'primevue/multiselect';
import Slider from 'primevue/slider';


// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css';
import "primevue/resources/themes/lara-light-blue/theme.css";
import 'primeflex/primeflex.css';

const base = axios.create({
    baseURL: "https://stats-api.grayriver.dk"
    // baseURL: "http://localhost:3000"
});

const toastOptions = {
    timeout: 2000
};


const app = createApp(App);
app.config.globalProperties.$http = base;
app.use(VueApexCharts)
app.use(PrimeVue);

// eslint-disable-next-line 
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Row', Row);
app.component('InputText', InputText);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('MultiSelect', MultiSelect);
app.component('Slider', Slider);

app.component('apexchart', VueApexCharts)
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(router)
app.use(Toast, toastOptions);
app.mount('#app')
