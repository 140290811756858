<template>
  <div class="container">
    <div class="row mt-5">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <p>Expenses by month</p>
          <apex-bar-stacked :chart-data="expenseByMonth" :chart-labels="months" :colors="chartColors" :border="'#FF0000'"
            :chart-data-type="'bar'" :rotate="false" :horizontal="true" :xAxisRotate="-45" />
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <p>Expenses by account</p>
          <apex-bar :chart-data="expenseByAccount.data" :chart-labels="expenseByAccount.labels" :colors="chartColors"
            :series-name="'WHAY'" :border="'#FF0000'" :chart-data-type="'bar'" :horizontal="false" :rotate="true"
            :xAxisRotate="-45" />
        </div>
      </div>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <p>Expenses by category</p>
          <apex-bar :chart-data="expenseByCategory.data" :chart-labels="expenseByCategory.labels" :colors="chartColors"
            :series-name="'WHAY'" :border="'#FF0000'" :chart-data-type="'bar'" :horizontal="false" :rotate="true"
            :xAxisRotate="-45" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { api } from "@/api/api";
import moment from "moment";
import ApexBarStacked from "@/components/Charts/ApexBarStacked";
import ApexBar from "@/components/Charts/ApexBar";

var _ = require("lodash");

export default {
  name: "StatsV",
  mixins: [api],

  components: {
    ApexBar,
    ApexBarStacked,
  },
  data() {
    return {
      accounts: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      expenses: [],
      chartColors: ["#F55555", "#6078ea", "#6094ea"],
    };
  },
  created() {
    this.getExpenses().then((response) => {
      this.expenses = response
        .map((entry) => {
          entry.raw_time = new moment.utc(entry.date);
          entry.year = entry.raw_time.format("YYYY");
          entry.month = entry.raw_time.format("MM");
          entry.week = entry.raw_time.format("WW");
          entry.day = entry.raw_time.isoWeekday();
          entry.time = entry.date.replace("Z", "");
          return entry;
        })
        .filter((expense) => !expense.income && !expense.internalMove);
    });
  },
  computed: {
    expenseByMonth() {
      let allData = this.expenses;

      let dataSeries = [];
      let dataByCategory = _.groupBy(allData, "category.name");

      for (let category in dataByCategory) {
        let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let months = _(dataByCategory[category])
          .groupBy("month")
          .map((objs, key) => {
            return {
              month: key,
              count: objs.length,
            };
          })
          .value();

        months = _.orderBy(months, "month", "asc");
        for (var key in months) {
          var index = Number(
            moment()
              .month(parseInt(months[key].month) - 1)
              .format("MM") - 1
          );

          let expenseRecords = dataByCategory[category].filter(
            (obj) => moment(obj.date).format("MM") == index + 1 // Add one to month
          );
          var prices = expenseRecords.map((obj) => obj.amount);
          // count[index] = months[key].count;
          count[index] = _.sum(prices);
        }

        dataSeries.push({
          name: dataByCategory[category][0].category.name,
          data: count,
          color: dataByCategory[category][0].category.color,
        });
      }

      return dataSeries;
    },
    expenseByAccount() {
      let data = this.expenses;
      let dataByAccount = _.chain(data)
        .groupBy("account.name")
        .map((value, key) => ({
          name: key,
          expenses: value,
          total: value
            .map((val) => val.amount)
            .reduce((partialSum, a) => partialSum + a, 0),
        }))
        .value();

      return {
        data: dataByAccount.map((item) => item.total),
        labels: dataByAccount.map((item) => item.name),
      };
    },
    expenseByCategory() {
      let data = this.expenses;
      let dataByCategory = _.chain(data)
        .orderBy('category.name')
        .groupBy("category.name")
        .map((value, key) => ({
          name: key,
          expenses: value,
          total: value
            .map((val) => val.amount)
            .reduce((partialSum, a) => partialSum + a, 0),
        }))
        .orderBy("total", "desc") // Order by total in descending order
        .value();




      return {
        data: dataByCategory.map((item) => item.total),
        labels: dataByCategory.map((item) => item.name),
      };
    },
  },
};
</script>
    
  
<style scoped>
@import "../assets/styles/bootstrap-overrides.css";

.card {
  margin-top: 10px;
}

.container {
  padding: 10px;
  height: 100%;
}
</style>