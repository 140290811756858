<template>
  <div class="container">
    <div class="row mt-5">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <p>Expenses by month Sarah</p>
          <apex-bar-stacked :chart-data="expenseByMonthSarah" :chart-labels="months" :colors="chartColors"
            :border="'#FF0000'" :chart-data-type="'bar'" :rotate="false" :horizontal="true" :xAxisRotate="-45" />
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <p>Expenses by month Christian</p>
          <apex-bar-stacked :chart-data="expenseByMonthChristian" :chart-labels="months" :colors="chartColors"
            :border="'#FF0000'" :chart-data-type="'bar'" :rotate="false" :horizontal="true" :xAxisRotate="-45" />
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <p>Expenses by month Fælles Budget</p>
          <apex-bar-stacked :chart-data="expenseByMonthBudget" :chart-labels="months" :colors="chartColors"
            :border="'#FF0000'" :chart-data-type="'bar'" :rotate="false" :horizontal="true" :xAxisRotate="-45" />
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <p>Expenses by month Fælles Mad</p>
          <apex-bar-stacked :chart-data="expenseByMonthFood" :chart-labels="months" :colors="chartColors"
            :border="'#FF0000'" :chart-data-type="'bar'" :rotate="false" :horizontal="true" :xAxisRotate="-45" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { api } from "@/api/api";
import moment from "moment";
import ApexBarStacked from "@/components/Charts/ApexBarStacked";


var _ = require("lodash");

export default {
  name: "StatsV",
  mixins: [api],

  components: {
    ApexBarStacked,
  },
  data() {
    return {
      accounts: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      expenses: [],
      chartColors: ["#F55555", "#6078ea", "#6094ea"],
    };
  },
  created() {
    this.getExpenses().then((response) => {
      this.expenses = response
        .map((entry) => {
          entry.raw_time = new moment.utc(entry.date);
          entry.year = entry.raw_time.format("YYYY");
          entry.month = entry.raw_time.format("MM");
          entry.week = entry.raw_time.format("WW");
          entry.day = entry.raw_time.isoWeekday();
          entry.time = entry.date.replace("Z", "");
          return entry;
        })
        .filter((expense) => !expense.income && !expense.internalMove);
    });
  },
  computed: {
    expenseByMonthSarah() {
      let allData = this.expenses;
      let filteredData = allData.filter(item => item.account._id == "63ada861fbad8e57558c1182")

      let dataSeries = [];
      let dataByCategory = _.groupBy(filteredData, "category.name");

      for (let category in dataByCategory) {
        let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let months = _(dataByCategory[category])
          .groupBy("month")
          .map((objs, key) => {
            return {
              month: key,
              count: objs.length,
            };
          })
          .value();

        months = _.orderBy(months, "month", "asc");
        for (var key in months) {
          var index = Number(
            moment()
              .month(parseInt(months[key].month) - 1)
              .format("MM") - 1
          );

          let expenseRecords = dataByCategory[category].filter(
            (obj) => moment(obj.date).format("MM") == index + 1 // Add one to month
          );
          var prices = expenseRecords.map((obj) => obj.amount);
          // count[index] = months[key].count;
          count[index] = _.sum(prices);
        }

        dataSeries.push({
          name: dataByCategory[category][0].category.name,
          data: count,
          color: dataByCategory[category][0].category.color,
        });
      }

      return dataSeries;
    },
    expenseByMonthChristian() {
      let allData = this.expenses;
      let filteredData = allData.filter(item => item.account._id == "6398cd9068857a68f9c1fb30")

      let dataSeries = [];
      let dataByCategory = _.groupBy(filteredData, "category.name");

      for (let category in dataByCategory) {
        let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let months = _(dataByCategory[category])
          .groupBy("month")
          .map((objs, key) => {
            return {
              month: key,
              count: objs.length,
            };
          })
          .value();

        months = _.orderBy(months, "month", "asc");
        for (var key in months) {
          var index = Number(
            moment()
              .month(parseInt(months[key].month) - 1)
              .format("MM") - 1
          );

          let expenseRecords = dataByCategory[category].filter(
            (obj) => moment(obj.date).format("MM") == index + 1 // Add one to month
          );
          var prices = expenseRecords.map((obj) => obj.amount);
          // count[index] = months[key].count;
          count[index] = _.sum(prices);
        }

        dataSeries.push({
          name: dataByCategory[category][0].category.name,
          data: count,
          color: dataByCategory[category][0].category.color,
        });
      }

      return dataSeries;
    },
    expenseByMonthBudget() {
      let allData = this.expenses;
      let filteredData = allData.filter(item => item.account._id == "63a05327ba2dc64e0b3dc0c2")

      let dataSeries = [];
      let dataByCategory = _.groupBy(filteredData, "category.name");

      for (let category in dataByCategory) {
        let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let months = _(dataByCategory[category])
          .groupBy("month")
          .map((objs, key) => {
            return {
              month: key,
              count: objs.length,
            };
          })
          .value();

        months = _.orderBy(months, "month", "asc");
        for (var key in months) {
          var index = Number(
            moment()
              .month(parseInt(months[key].month) - 1)
              .format("MM") - 1
          );

          let expenseRecords = dataByCategory[category].filter(
            (obj) => moment(obj.date).format("MM") == index + 1 // Add one to month
          );
          var prices = expenseRecords.map((obj) => obj.amount);
          // count[index] = months[key].count;
          count[index] = _.sum(prices);
        }

        dataSeries.push({
          name: dataByCategory[category][0].category.name,
          data: count,
          color: dataByCategory[category][0].category.color,
        });
      }

      return dataSeries;
    },
    expenseByMonthFood() {
      let allData = this.expenses;
      let filteredData = allData.filter(item => item.account._id == "63a05315ba2dc64e0b3dc0c1")

      let dataSeries = [];
      let dataByCategory = _.groupBy(filteredData, "category.name");

      for (let category in dataByCategory) {
        let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let months = _(dataByCategory[category])
          .groupBy("month")
          .map((objs, key) => {
            return {
              month: key,
              count: objs.length,
            };
          })
          .value();

        months = _.orderBy(months, "month", "asc");
        for (var key in months) {
          var index = Number(
            moment()
              .month(parseInt(months[key].month) - 1)
              .format("MM") - 1
          );

          let expenseRecords = dataByCategory[category].filter(
            (obj) => moment(obj.date).format("MM") == index + 1 // Add one to month
          );
          var prices = expenseRecords.map((obj) => obj.amount);
          // count[index] = months[key].count;
          count[index] = _.sum(prices);
        }

        dataSeries.push({
          name: dataByCategory[category][0].category.name,
          data: count,
          color: dataByCategory[category][0].category.color,
        });
      }

      return dataSeries;
    },
  },
};
</script>
    
  
<style scoped>
@import "../assets/styles/bootstrap-overrides.css";

.card {
  margin-top: 10px;
}

.container {
  padding: 10px;
  height: 100%;
}
</style>